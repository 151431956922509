@tailwind components;
@tailwind utilities;

.step-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-steps,
.right-steps {
  display: flex;
  justify-content: flex-start;
}

.center-steps {
  display: flex;
  justify-content: center;
}
