@tailwind components;
@tailwind utilities;
html {
  width: 100%;
  height: 100%;
  margin: 0;
}

header {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
}

body {
  width: 100% !important;
  height: 100% !important;
  background-color: #d9d9d9 !important;
  background-size: cover;
  position: absolute;
  z-index: -1;
}

.container {
  flex: 1 0 auto;
}
@media (max-height: 640px) {
  body .container-section-body,
  body .container-section-body-none,
  .container-section-index,
  .container-section-header-none,
  .show-container-section {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar-select-empreendimento {
    width: 110%;
    margin-right: 22px;
    min-width: -webkit-fill-available;
  }
  .form-control {
    margin-bottom: 1%;
  }
  .button-filtro {
    margin: 1%;
    position: relative;
    align-self: flex-end;
  }
  .p-inputtext {
    width: 100% !important;
  }
  .react-datepicker__input-container {
    margin-top: 8px;
  }
  .body-content {
    container-type: inline-size;
  }
  .form-select {
    margin-bottom: 1%;
  }
  .col-md-4 {
    margin-right: 2%;
  }
  .col-md-1 {
    margin-right: 5%;
  }
}

@media (max-width: 1100px) {
  body .container-section-body,
  body .container-section-body-none {
    /* Adjust styles for medium-sized screens */
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.sidebar-select-empreendimento {
  width: 90%;
}

.App {
  width: 100% !important;
  height: 100% !important;
  text-align: center;
}

.AppLayout {
  width: 100% !important;
  height: 100vh !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.svgNavBarLogo {
  margin-left: 22px;
  margin-right: 12px;
}

.svgMarcaDagua {
  margin-left: 22px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ulConfig {
  margin: 0px !important;
  height: 30px;
}

.selectEmpreendimento {
  color: #e8550f !important;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iconNavBar {
  color: #fff !important;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px !important;
}

.navbar-header {
  background: #282c34;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.navBar {
  color: #fff !important;
  text-align: center !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 0px !important;
  display: contents !important;
}

.content-breads {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 8px;
  margin-left: 22px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  background: #282c34;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

li {
  height: fit-content;
  display: flex;
}

.iconBarra {
  width: -webkit-fill-available;
  margin-right: 6px !important;
  margin-left: 6px !important;
  height: 1px;
}

.svgNavBar {
  display: flex;
  align-items: center !important;
  padding: 0 !important;
}

.home {
  width: 22px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 3px;
}

.empreendimento {
  width: 22px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 5px;
  margin-top: 1px;
}

.corretores {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.fornecedor {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.produto {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 3px;
  margin-left: 8px;
}
.imoveis {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.locacao-contratos {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.revenda-imovel {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.revenda-caracteristicas {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.tipoImoveis {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.configvenda {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.configcarteira {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.tabelapreco {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.classificacaoImoveis {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.gerar-venda {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.venda-avulsa {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.boletos {
  width: 19.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.import-user {
  width: 22.69px;
  margin-right: 8px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 1px;
}
.importar-lancamento {
  width: 22.69px;
  margin-right: 8px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 1px;
}
.modelo-relatorio {
  width: 22.69px;
  margin-right: 8px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 1px;
}

.renegociacao {
  width: 27.69px;
  height: 27px;
  margin-right: 8px;
}

.modelo-contrato {
  width: 20.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.modelo-carta-cobranca {
  width: 20.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.reajuste {
  width: 22.69px;
  height: 42px;
  flex-shrink: 0;
  margin-right: 6px;
  margin-left: 2px;
  margin-bottom: 3px;
}

.relatorioVendaIntermed {
  width: 20.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.empreendimentoObra {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.contrato {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.mapa-cemiterio {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 3px;
  margin-left: 8px;
}
.cadastro-cemiterio {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 3px;
  margin-left: 8px;
}
.subTipoImoveis {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.compradores {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.financeiro {
  width: 22px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 5px;
}

.carteira {
  width: 19px;
  height: 19px;
  flex-shrink: 0;
  margin-right: 6px;
}

.vendas {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  margin-right: 3px;
}
.revenda {
  width: 22px;
  margin-right: 5px;
  height: 21px;
  flex-shrink: 0;
}
.locacao {
  width: 22px;
  margin-right: 5px;
  height: 21px;
  flex-shrink: 0;
}
.locacao-imovel {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.cemiterio {
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 3px;
}

.estrutura {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 8px;
}
.tipo-jazigo {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 8px;
}
.jazigo {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 8px;
}
.jazigo-grupos {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 8px;
}
.inumados {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 8px;
}
.pessoas {
  width: 18px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 3px;
  margin-left: -3px;
}

.audit {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: 1px;
}
.regua-cobranca {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  margin-right: 2px;
  margin-left: 1px;
}
.inadimplencia-sintetico {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.relatorio-fornecedores {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.provedor {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: 1px;
}
.tela-cobranca {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: 1px;
}
.mapa-inadimplencia {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: 1px;
}
.modelo-mensagem {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  margin-right: 2px;
  margin-left: 1px;
}
.acao {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  margin-right: 2px;
  margin-left: 1px;
}
.Indice {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: 1px;
}

.tipoIndice {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: 1px;
}

.exclusao-lotes {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 8px;
  margin-left: 1px;
}

.navBar-Final {
  border: none !important;
}

.ulNavBarMenu {
  vertical-align: middle;
  display: contents !important;
  margin: 0px !important;
  height: 30px;
}

.table-responsive {
  padding-right: 26px;
  height: 100%;
}

.container {
  display: contents !important;
}

.menuUser {
  justify-content: end;
  padding-right: 22px;
}

.dropdownPerfil {
  padding: 0px !important;
  width: 92%;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 4px;
}

.dropdownMenuTable {
  padding: 0px !important;
  width: 92%;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 4px;
}

.dropdownSelect {
  display: flex !important;
  padding: 0px !important;
  width: 92% !important;
  height: 30px !important;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 4px !important;
  font-size: 16px;
}

.dropdownPerfil:hover {
  background-color: #6c757d;
}

.dropdownSelect:hover {
  background-color: #6c757d;
}

.svgIconUser {
  padding-top: 6px;
  margin-right: 7px;
}

.imgProfile {
  border: 3px solid #e8550f;
  border-radius: 30px;
}

.dropdownPerfil > .dropdown-toggle::after {
  margin-top: 6px;
}

#nav-dropdown-dark-example {
  padding: 0px;
  padding-top: 4px !important;
  vertical-align: middle;
}

.content-header {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  background-color: #494949;
}

.p-speeddial .p-component .p-speeddial-linear .p-speeddial-direction-left {
  flex-direction: 0% !important;
  justify-content: 0% !important;
}
.speed-dial-container {
  text-align: center;
}
.nav-link {
  display: inline-flex !important;
  padding: 0px !important;
}
/* NOTIFICAÇÂO */

.dropdown-notification .notification-body {
  scrollbar-width: none;
}
/* Outras */
.dropdown-notification .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -230px !important;
  margin-top: var(--bs-dropdown-spacer);
  max-height: 651px;
}
.notification-header {
  display: flex;
  justify-content: space-between;
  margin-right: 7px;
}
.body-notificacao-title {
  color: #e0dede;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 0px;
  display: flex;
}

.button-reload-notificacao:hover {
  background-color: #7072756b !important;
}
.button-trash-notificacao:hover {
  background-color: #7072756b !important;
}
.iconNavBar .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -110px !important;
  margin-top: var(--bs-dropdown-spacer);
}
.card-notificacao {
  color: #e0dede;
  border-radius: 5px;
  background-color: #292b2f6b;
  margin: 8px;
  margin-top: 0px;
  padding: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.card-notificacao:hover {
  background-color: #65656559;
}
.notification-body {
  display: inline-flex !important;
  flex-direction: column;
  max-height: 568px;
  overflow-y: scroll;
}
.card-notificacao .p-card-body {
  padding: 0px;
  padding-left: 0px !important;
}
.check-notificacao .form-check-input {
  background-color: #292b2f6b;
}
.body-notificacao {
  color: #e0dede;
  width: 250px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: normal;
  align-items: center;
  display: flex;
}
.body-notificacao-default {
  color: #e0dede;
  width: 250px;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}
.selectEmpreendimento .dropdown-toggle::after {
  display: inline-block !important;
  margin-left: 0.255em;
  margin-top: 6px !important;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.label-select-filial {
  display: flex;
  margin: 0px;
  align-items: center;
  font-size: 15px !important;
}
.dropdown-toggle::after {
  display: contents !important;
  margin-left: 0.255em;
  margin-top: 10px !important;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.content-footer {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 8px;
  margin-left: 22px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 4px;
  background: #282c34;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.iconConfig {
  padding-top: 4px;
  margin-right: 7px;
  margin-top: 2px;
  width: 27px;
  height: 30px;
}

.iconNotification {
  width: 18px;
  padding: 2px;
  display: flex;
  height: 21px;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.menu-navbar {
  display: flex;
  justify-content: space-between;
}

.me-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.button-create {
  width: 24px;
  margin-top: 2px;
  padding: 1px;
  height: 24px;
  border: 2px solid #01c209;
  border-radius: 30px;
  background-color: #01c209;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.button-impressora {
  width: 30px;
  padding: 3px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 30px;
  background-color: #fff;
}
.button-editar {
  width: 24px;
  margin-top: -5px;
  vertical-align: middle;
  height: 24px;
  border: 2px solid #e8550f;
  border-radius: 30px;
  background-color: #e8550f;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.button-edit {
  width: 24px;
  margin-top: 2px;
  vertical-align: middle;
  height: 24px;
  border: 2px solid #e8550f;
  border-radius: 30px;
  background-color: #e8550f;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.p-button.p-button-warning,
.p-button-group.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button,
.p-fileupload-choose.p-button-warning {
  color: #ffffff !important;
}

.button-delete {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  border: 2px solid #ff1414;
  border-radius: 30px;
  background-color: #ff1414;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.label-select-empreendimento {
  margin-bottom: 0px !important;
  color: #e8550f;
  display: flex;
  justify-content: center;
}

.button-mini-menu {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  border: 2px solid #7b7b7b;
  border-radius: 30px;
  background-color: #7b7b7b;
  justify-content: center;
  display: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.p-speeddial-list {
  display: contents;
}
.svgButtonEdit {
  width: 16px;
  height: 16px;
  margin-top: -7px;
}

.navbar-nav {
  flex-direction: row !important;
  margin-right: 11px;
}

.selector-for-some-widget {
  box-sizing: content-box;
}

.nav-item {
  margin-left: 4px;
  margin-right: 4px;
}

.svgButtonDelete {
  margin-top: -9px;
  margin-left: -2px;
}

.svgButtonMenu {
  display: flex;
  justify-content: center;
}

html,
body {
  min-height: 100vh;

  flex-direction: column;
}

header {
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
}

footer {
  position: fixed;
  width: 100%;
  margin-bottom: 12px;
  bottom: 0;
  float: inline-end;
}

.container {
  flex: 1 0 auto;
}

.icon-footer {
  margin-top: 3px;
  margin-left: 8px;
  width: 101.99px;
  height: 29.995px;
  flex-shrink: 0;
}

.label-footer {
  color: #fff;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
  display: flex;
}

.label-hour-footer {
  margin-top: 10px;
  color: #fff;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.icon-copy {
  width: 18px;
  height: 16px;
  flex-shrink: 0;
  margin-top: 9px;
  margin-right: 5px;
}

.div-footer {
  display: flex;
  padding-left: 8px;
  padding-right: 5px;
}

.label-year-footer {
  color: #fff;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 6px;
  margin-left: 6px;
}

.label-boletos-list {
  color: #b3b3b3;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 6px;
  margin-left: 6px;
}

.data-hour {
  position: relative;
  left: 60px;
  height: fit-content;
}

.p-card-content {
  padding: 5px !important;
}
.container-section-home-visible {
  margin-top: 10px;
  display: grid;
  width: auto;
  margin-bottom: 80px;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 22px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.container-section-home {
  margin-top: 10px;
  display: grid;
  width: auto;
  margin-bottom: 80px;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 22px;
  margin-left: 22px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.container-section-body {
  margin-top: 0px;
  display: flex;
  width: auto;
  margin-bottom: 80px;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 22px;
  /* padding-right: 12px; */
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.card-table-imovel {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin: 12px;
}

.container-section-body-none {
  margin-top: 0px;
  display: flex;
  width: auto;
  margin-bottom: 80px;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 5.5%;
  padding-right: 12px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.container-section-header {
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 22px;
  padding-right: 0px;
  padding-left: 0px;
}
.container-section-reajuste {
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: 99%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 10px;
  padding-right: 0px;
  padding-left: 8px;
}

.container-section-header-none {
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
}
.container-section-reajuste-none {
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: 99%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
}

.show-container-section {
  margin-top: 52px;
  display: flex;
  width: 97%;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
}

@media (max-height: 640px) {
  body .container-section-body {
    margin-top: 0px;
    display: flex;
    width: auto;
    margin-bottom: 79px;
    flex-direction: column;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-right: 22px;
    /* padding-right: 12px; */
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  body .container-section-body-none {
    margin-top: 0px;
    display: flex;
    width: auto;
    margin-bottom: 0px;
    flex-direction: column;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    margin-bottom: 5.5%;
    padding-right: 12px;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .container-section-index {
    width: 88% !important;
    min-height: auto !important;
    margin-left: 24px !important;
  }

  .container-section-header {
    display: flex;
    width: auto;
    margin-bottom: 4px;
    flex-direction: column;
    height: auto;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 0px;
  }
  .container-section-reajuste {
    display: flex;
    width: auto;
    margin-bottom: 4px;
    flex-direction: column;
    height: 99%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-right: 0px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .container-section-header-none {
    display: flex;
    width: auto;
    margin-bottom: 4px;
    flex-direction: column;
    height: auto;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .container-section-reajuste-none {
    display: flex;
    width: auto;
    margin-bottom: 4px;
    flex-direction: column;
    height: 99%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .show-container-section {
    margin-top: 52px;
    display: flex;
    width: 97%;
    margin-bottom: 4px;
    flex-direction: column;
    height: auto;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .fornecedor-form {
    display: block !important;
    height: 320px;
    margin-left: 22px;
    padding-left: 12px;
    padding-right: 36px;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }
  .inumado-form {
    display: block !important;
    height: 320px;
    margin-left: 22px;
    padding-left: 12px;
    padding-right: 36px;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }
}

@media (max-width: 1100px) {
  body .container-section-body {
    margin-top: 0px;
    display: flex;
    width: auto;
    flex-direction: column;
    margin-bottom: 0px;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-right: 22px;
    margin-left: 22px;
    padding-right: 12px;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  body .container-section-body-none {
    margin-top: 0px;
    display: flex;
    width: auto;
    margin-bottom: 0px;
    flex-direction: column;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    margin-bottom: 5.5%;
    padding-right: 12px;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .container-section-index {
    margin-top: 110px !important;
    width: 88% !important;
    min-height: auto !important;
    margin-left: 23px !important;
  }

  .svgNavBarLogoUsuario {
    width: 300px !important;
    height: 300px !important;
    margin-left: 18px;
    margin-right: 12px;
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .container-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    height: auto;
  }

  .sidebar {
    overflow-x: auto;
    margin-right: 22px;
    transition: opacity 1s ease-in-out;
  }

  .sidebar-navbar > a {
    margin: 4px;
  }

  .col {
    flex: none !important;
  }

  .form-user-create {
    margin-top: 10px;
  }

  .dateFormControlMd12 {
    width: 234px !important;
    margin-top: 10px !important;
  }

  .pessoa-form {
    display: block !important;
    height: 320px;
    margin-left: 22px;
    padding-left: 12px;
    padding-right: 36px;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }

  .progressBar {
    display: none !important;
  }

  .formulario-empreendimento {
    padding-top: 10px;
  }

  .container-section-header {
    display: flex;
    width: auto;
    margin-bottom: 8px;
    flex-direction: column;
    height: auto;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 0px;
  }
  .container-section-reajuste {
    display: flex;
    width: auto;
    margin-bottom: 8px;
    flex-direction: column;
    height: 99%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-right: 0px;
    padding-right: 8px;
    margin-left: 22px;
    padding-left: 8px;
  }

  .container-section-header-none {
    display: flex;
    width: auto;
    margin-bottom: 8px;
    flex-direction: column;
    height: auto;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .container-section-reajuste-none {
    display: flex;
    width: auto;
    margin-bottom: 8px;
    flex-direction: column;
    height: 99%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .show-container-section {
    margin-top: 52px;
    display: flex;
    width: 88.8%;
    margin-bottom: 12px;
    flex-direction: column;
    height: auto;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-left: 22px;
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .homeMenu,
  .empreendimentoMenu,
  .financeiroMenu,
  .carteiraMenu,
  .vendasMenu,
  .pessoasMenu,
  .indicator-sidebar,
  .indicator-menu {
    display: none !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .menu-navbar {
    display: inline-block !important;
    flex-direction: column !important;
    display: flex !important;
    margin: 5px;
  }

  .svgNavBar {
    /* display: flex !important; */
    align-items: center !important;
    padding: 8px !important;
    text-align: center !important;
    justify-content: center;
  }

  .iconBarra,
  .home,
  .empreendimento,
  .financeiro,
  .carteira,
  .vendas,
  .pessoas {
    display: none !important;
  }

  .paginaEmConstrucao {
    margin-left: 22px;
    margin-right: 12px;
    width: 900px !important;
    height: 900px !important;
  }

  .navbar-profile {
    margin-top: 8px !important;
    display: flex !important;
    justify-content: center;
  }

  .logo-footer-qimob {
    display: none;
  }

  .content-footer {
    display: grid;
    justify-content: center;
    vertical-align: middle;
    margin-top: 10px;
    margin-left: 22px;
    margin-right: 22px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 4px;
    background: #494949;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  footer {
    width: 100%;
    bottom: 0px;
  }

  .icon-copy {
    display: none;
  }

  .div-footer {
    display: inline-flex;
    margin-top: 9px;
    padding-left: 8px;
    padding-right: 5px;
  }

  .label-year-footer {
    color: #fff;
    text-align: center;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 6px;
    margin-left: 6px;
  }

  .data-hour {
    display: none;
  }

  .label-footer {
    color: #fff;
    text-align: right;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.table-listagem {
  margin: 15px;
  width: 100%;
  display: flex;
  height: -webkit-fill-available;
  flex-direction: column;
}

.homeMenu {
  display: flex;
  position: absolute;
  left: 39%;
  transform: translateX(-50%);
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #d9d9d9 transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 71%;
}

.empreendimentoMenu {
  display: flex;
  position: absolute;
  left: 45.9%;
  transform: translateX(-50%);
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #d9d9d9 transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 71%;
}

.financeiroMenu {
  display: flex;
  position: absolute;
  left: 54.5%;
  transform: translateX(-50%);
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #d9d9d9 transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 71%;
}

.carteiraMenu {
  display: flex;
  position: absolute;
  left: 61.4%;
  transform: translateX(-50%);
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #d9d9d9 transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 71%;
}

.vendasMenu {
  display: flex;
  position: absolute;
  left: 66.8%;
  transform: translateX(-50%);
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #d9d9d9 transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 71%;
}

.pessoasMenu {
  display: flex;
  position: absolute;
  left: 72.5%;
  transform: translateX(-50%);
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #d9d9d9 transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 71%;
}

.menu-container {
  display: contents;
}

.dashFinanceiroBlocos {
  display: flex;
  width: 300px;
  height: 100px;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
  margin-left: 22px;
  margin-right: 22px;
  border-radius: 4px;
  border: 2px solid #e8550f;
  background: #494949;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.subNavBar {
  color: #494949 !important;
  text-align: center !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 0px !important;
  display: flex !important;
  justify-content: center;
  border-radius: 4px;
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.sidebar {
  margin-bottom: 12px;
  flex-direction: column;
  height: fit-content;
  width: auto;
  max-height: 60% !important;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 10px;
  /* Inicialmente, fora da tela à esquerda */
  margin-top: 10px;
  animation: fadeInFromLeft 0.3s ease-in-out;
  overflow-x: auto;
}

.sidebar-select-empreendimento {
  margin-bottom: 12px;
  flex-direction: column;
  height: fit-content;
  border-radius: 4px;
  background: #ffffff;
  width: auto;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 10px;
  /* Inicialmente, fora da tela à esquerda */
  padding: 8px;
  margin-top: 10px;
  animation: fadeInFromLeft 0.3s ease-in-out;
}

.content {
  margin-bottom: 12px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #282c34;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  /* Inicialmente, fora da tela à esquerda */
  padding: 8px;
  animation: fadeInFromLeft 0.3s ease-in-out;
}

.sidebar-navbar {
  display: contents !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.sidebar-text {
  text-align: left !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding-left: 7px !important;
  width: 100%;
  height: 47px;
  text-decoration: none;
}

.sidebar-text:hover {
  text-decoration: none !important;
  background-color: #585c65;
  color: #fff !important;
}
.sidebar-text-noIcon {
  color: #fff !important;
  text-align: left !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 2px !important;
  margin: 3px;
  display: block !important;
  width: 200px;
  text-decoration: none;
  text-align: center !important;
}

.usuario {
  width: 22.69px;
  margin-right: 8px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 1px;
}

.group-user {
  width: 22.69px;
  margin-right: 8px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 1px;
}

.filial {
  width: 22.69px;
  margin-right: 8px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 1px;
}

.contas-receber {
  width: 27.69px;
  height: 29px;
  margin-right: 5px;
  flex-shrink: 0;
}

.rotinas-especiais {
  width: 22.69px;
  height: 27px;
  margin-left: 1px;
  margin-right: 8px;
}

.contas-pagar {
  width: 23.69px;
  height: 26px;
  margin-right: 9px;
}

.movimentacaofinanceira {
  width: 19.69px;
  margin-right: 12px;
  height: 26px;
  flex-shrink: 0;
}

.plano-conta {
  width: 22.69px;
  height: 27px;
  margin-right: 8px;
}

.conta-financeira {
  width: 22.69px;
  height: 27px;
  margin-right: 8px;
}

.relatorioFinanceiro {
  width: 22.69px;
  height: 27px;
  margin-right: 8px;
}
.conta-corrente {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.relatorio-disponibilidade {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.relatorio-lancamento {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.ranking {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.comissoes {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.receberIntermed {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.propostas {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.lancamentos {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.timer {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.reservas-corretores {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.reservas-internas {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.fechamento-caixa {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.validacao-documentos {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}
.receber-intermediacao {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.espelho-vendas {
  width: 23px;
  margin-right: 5px;
  height: 23px;
  flex-shrink: 0;
  margin-left: 5px;
}

.relatorio-imoveis {
  width: 22.69px;
  margin-right: 5px;
  height: 22px;
  flex-shrink: 0;
  margin-left: 5px;
}

.centro-custo {
  width: 22.69px;
  height: 27px;
  margin-right: 8px;
}

.contentSearch-header {
  display: flex;
  margin: 8px;
}

.contentSearch-header-imovel {
  display: flex;
  margin: 8px;
  justify-content: space-between;
}

.contentSearch-body {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
}

.contentTraçoBodyHeader {
  border-bottom: 1px solid #494949;
}
.contentTraçoBodyForm {
  border-bottom: 2px solid #8888;
  margin: 5px;
}

.contentSearch h3 {
  margin: 5px;
  color: #494949;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.divInputSelect {
  width: 312px !important;
  height: 37px !important;
  padding: 0px !important;
  min-height: 0px !important;
}

.ulInputSelect {
  width: 312px !important;
  padding: 0px !important;
  min-height: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.37);
  border-radius: 4px;
  background: #f1f1f1;
}

.liInputSelect {
  width: 312px !important;
  height: 37px !important;
  padding-top: 6px;
  padding-left: 12px !important;
  min-height: 0px !important;
  border-left: 6px solid #494949;
  border-right: 1px solid #494949;
  border-bottom: 1px solid #494949;
  color: rgba(6, 6, 6, 0.47);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.liInputSelect:hover {
  background: #d2d2d2;
}

.inputSelect {
  width: 312px !important;
  height: 37px !important;
  min-height: 0px !important;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid rgba(0, 0, 0, 0.37);
  background: #ffffff;
  color: rgba(6, 6, 6, 0.47);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputSelect:hover {
  border: 1px solid #494949;
}

.inputSelect:focus {
  border: 1px solid #494949;
}

.inputSelect:scope {
  border: 1px solid #494949;
}

.dateInputSelect {
  width: 180px !important;
  height: 37px !important;
  min-height: 0px !important;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid rgba(0, 0, 0, 0.37);
  background: #ffffff;
  color: rgba(6, 6, 6, 0.47);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 12px;
}

.label-date {
  color: rgba(6, 6, 6, 0.47);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 9px;
  margin-left: 16px;
  margin-right: 16px;
}

.dateInputSelect:hover {
  border: 1px solid #494949;
}

.dateInputSelect:focus {
  border: 1px solid #494949;
}

.dateInputSelect:scope {
  border: 1px solid #494949;
}

.labelInputSelect {
  color: rgba(6, 6, 6, 0.47);
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-date-input {
  display: flex;
  align-items: center;
}

.calendar-icon {
  margin-left: 5px;
  width: 16px;
}

.menu-context {
  width: 20px;
}

.filter-campos {
  display: flex;
  justify-content: space-between;
}

.icon-filtros-avançados {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.37);
  background: #fff;
  width: 33px;
  height: 32px;
  flex-shrink: 0;
}

.buttonFilterSearch {
  width: 81px !important;
  height: 35px !important;
  border-radius: 4px !important;
  background: #10c117 !important;
  border: none !important;
  margin-left: 7px;
}

.buttonClearFilter {
  width: 81px !important;
  height: 35px !important;
  margin-left: 7px;
  border-radius: 4px !important;
  background-color: #ff1414 !important;
  border: none !important;
  margin-right: 10px;
}

.labelFilterSearch {
  color: #fff;
  text-align: center;
  margin-top: 4px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.labelClearFilter {
  color: #fff;
  text-align: center;
  margin-top: 4px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.active > .page-link,
.page-link.active {
  z-index: 0 !important;
  color: #494949 !important;
  background-color: #d2d2d2 !important;
  border-color: #d2d2d2 !important;
  border-radius: 20px;
  margin: 3px;
}

.pagination {
  justify-content: center;
}

.p-datepicker.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  z-index: 2000 !important;
}

.p-datepicker.p-component.custom-calendar-panel.p-datepicker-monthpicker.p-ripple-disabled {
  z-index: 2000 !important;
}
.p-datepicker.p-component.custom-calendar-panel.p-ripple-disabled {
  z-index: 2000 !important;
}
.p-multiselect-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  z-index: 2000 !important;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: #494949 !important;
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #e8550f;
}

.Auth-form {
  width: 420px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #494949;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.link-perfil {
  color: rgb(255, 255, 255, 0.75) !important;
  text-decoration: none;
}

.imgNoProfile {
  border: 5px solid #e8550f;
  border-radius: 120px;
}

.perfil {
  margin-top: 103px;
  display: flex;
  width: 97%;
  height: 585px !important;
  margin-bottom: 12px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
}

.form-perfil {
  text-align: justify !important;
  margin: 8px !important;
}

.foto-perfil {
  align-items: center;
  display: flex;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.42);
  justify-content: center;
  height: 585px;
}

.button-file {
  margin-top: 8px;
  background-color: #e8550f !important;
  border: none !important;
}

.button-file:hover {
  background-color: #494949 !important;
}

.button-save-or-update {
  background-color: #10c117 !important;
  border: none !important;
  margin-top: 0 !important;
}

.group-form {
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 5px;
  display: flex;
  justify-content: left;
  padding-right: 25px;
}

.pessoa-form {
  padding: 5px;
  padding-left: 12px;
  padding-right: 36px;
  margin-left: 22px;
  display: flex;
}

.conjugue-form {
  margin-left: 22px;
}
.usuario-form {
  margin-bottom: 25px;
  margin-top: 25px;
  margin-left: 22px;
  padding: 5px;
  padding-right: 25px;
  height: -webkit-fill-available;
}
.fornecedor-form {
  margin-bottom: 25px;
  margin-top: 25px;
  margin-left: 22px;
  padding: 5px;
  padding-right: 25px;
  height: -webkit-fill-available;
}

.inumado-form {
  margin-bottom: 25px;
  margin-top: 25px;
  margin-left: 22px;
  padding: 5px;
  padding-right: 25px;
  height: -webkit-fill-available;
}

.imovel-form {
  margin-bottom: 25px;
  margin-top: 5px;
  padding: 5px;
  padding-right: 25px;
  margin-top: 10px;
  margin-left: 11px;
}
.indice-form {
  margin-bottom: 25px;
  margin-top: 5px;
  padding: 5px;
  padding-right: 25px;
}
.forms {
  margin-bottom: 25px;
  margin-top: 5px;
  padding: 5px;
  padding-right: 25px;
  margin-top: 10px;
  margin-left: 22px;
}
.number-circle {
  width: 10%;
  height: 70px;
  padding: 5px;
  border-radius: 20px;
  font-size: 12px;
  background-color: #282c34;
  color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.38);
  font-weight: bold;
}

.line-bar {
  height: 6px;
  width: 5%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.38);
  background-color: #282c34;
}

.number-active {
  background-color: #e8550f;
}

.line-bar-active {
  background-color: #e8550f;
}

.button-back-form {
  margin-right: 5px;
  border: none !important;
  background-color: #494949 !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.38);
}

.button-next-form {
  background-color: #e8550f !important;
  border: none !important;
  margin-right: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.38);
}

.botoes-progress-bar {
  display: flex;
}

.button-end-form {
  background-color: #10c117 !important;
  border: none !important;
  margin-right: 5px;
}

.dateFormControlMd9 {
  width: 175px !important;
  height: 37px !important;
  min-height: 0px !important;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid rgba(0, 0, 0, 0.28);
  background: #ffffff;
  color: rgba(6, 6, 6, 0.47);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dateFormImovel {
  width: 135px !important;
  height: 37px !important;
  min-height: 0px !important;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid rgba(0, 0, 0, 0.28);
  background: #ffffff;
  color: rgba(6, 6, 6, 0.47);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label-form-create-imovel {
  color: rgba(6, 6, 6, 0.47);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.label-form-create-imovel {
  color: rgba(6, 6, 6, 0.47);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.label-table-not-found {
  color: rgba(6, 6, 6, 0.47);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center !important;
  align-items: center;
  margin: 12px;
  display: grid;
  overflow-y: hidden !important;
}

.label-not-found {
  color: rgba(6, 6, 6, 0.47);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center !important;
  align-items: center;
  margin: 4px;
  display: grid;
  overflow-y: hidden !important;
}

.label-select-not-found {
  color: rgba(255, 252, 252, 0.87);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center !important;
  align-items: center;
  margin: 8px;
}

.dateFormFinanceiro {
  width: 97%;
  height: 32px !important;
  min-height: 0px !important;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid rgba(0, 0, 0, 0.28);
  background: #ffffff;
  color: rgba(6, 6, 6, 0.47);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dateFormControlMd12 {
  width: 345px;
  height: 37px !important;
  min-height: 0px !important;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid rgba(0, 0, 0, 0.28);
  background: #ffffff;
  color: rgba(6, 6, 6, 0.47);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dateFormImovelMd12 {
  width: 230px !important;
  height: 37px !important;
  min-height: 0px !important;
  border-radius: 4px;
  padding-left: 12px;
  border: 1px solid rgba(0, 0, 0, 0.28);
  background: #ffffff;
  color: rgba(6, 6, 6, 0.47);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formulario-usuario {
  overflow-y: scroll;
  height: 520px;
}

/* Works on Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: #656565 #d9d9d9;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #d9d9d9;
}

*::-webkit-scrollbar-thumb {
  background-color: #656565;
  border-radius: 20px;
  border: 3px solid #d9d9d9;
}

.button-add-endereco {
  margin: 5px;
  margin-bottom: 15px;
  height: 20px;
  display: flex;
  margin-right: 11px;
  justify-content: end;
  border-radius: 30px;
}

.button-delete-endereco {
  margin: 5px;
  width: 35px !important;
}

.row-delete-button {
  display: flex;
  justify-content: end;
}

.container-section-index {
  display: flex;
  width: auto;
  margin-bottom: 80px;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  margin-left: 12px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
  align-items: center;
}

.form-user-create {
  display: flex;
  width: auto;
  flex: 1;
  margin-bottom: 80px;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 22px;
}

.form-user-perfil {
  display: flex;
  width: auto;
  margin-bottom: 80px;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  margin-left: 12px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
}

.swal2-confirm {
  background-color: #494949 !important;
}

.paginaEmConstrucao {
  margin-left: 22px;
  margin-right: 12px;
}

.create-foto {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.menu-table {
  width: 5px;
}

.label-card-venda {
  color: #494949 !important;
  text-align: center !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.pageAccessDenied {
  color: #494949 !important;
  text-align: center !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 0px !important;
  display: flex !important;
  width: 100%;
  min-height: 750px !important;
  justify-content: center;
  margin-top: 55px;
  border-radius: 4px;
  align-items: center;
  background: #282c34;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  flex-direction: column;
}

.icon-list-option {
  border: 1px solid rgba(0, 0, 0, 0.37);
  margin: 8px;
  margin-right: 0px !important;
  padding: 4px;
  border-radius: 6px;
}

.icon-list-option-select {
  border-radius: 6px;
  margin: 8px;
  margin-right: 0px !important;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.48);
}

.show-more-button {
  color: #fff;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.barra-detail-card {
  background-color: #747474;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.68);
}

.icon-card-option {
  border: 1px solid rgba(0, 0, 0, 0.37);
  margin: 8px;
  margin-right: 0px !important;
  padding: 4px;
  border-radius: 6px;
}

.icon-card-option-select {
  border-radius: 6px;
  margin: 8px;
  margin-right: 0px !important;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.48);
}

.pageAccessDenied h1 {
  color: #e8550f;
  text-shadow: 0px 2px 1px #000;
  font-family: Krub;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 1100px) {
  body {
    overflow-y: scroll !important;
  }

  .table-responsive {
    height: 100%;
  }

  .filter-campos {
    display: block;
  }

  .inputSelect {
    width: 305px !important;
    margin-left: 6px;
  }

  .div-calendar {
    display: inline-flex;
    flex-direction: column;
    margin-left: 12px;
  }

  .label-date {
    display: none;
  }

  .contentSearch-body {
    display: flex;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 0px;
    justify-content: center;
    flex-direction: column;
  }

  .dateInputSelect {
    width: 280px !important;
    height: 37px !important;
    min-height: 0px !important;
    border-radius: 4px;
    padding-left: 12px;
    border: 1px solid rgba(0, 0, 0, 0.37);
    background: #ffffff;
    color: rgba(6, 6, 6, 0.47);
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 6px;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .div-filtros-avancados {
    display: auto;
    justify-content: center;
    margin-top: 35px;
  }

  .logoQimob {
    width: 50px !important;
    position: relative;
    top: 250px;
  }

  .logoQimobLoad {
    position: absolute;
    width: 600px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    animation-name: my-animation;
    animation-duration: 0.6s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.logoQimob {
  width: 350px;
  position: relative;
  top: 250px;
}

.loading {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #282c34;
}

.div-filtros-avancados {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.logoQimobLoad {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.form-create-group {
  position: relative;
  width: auto;
  margin-left: 12px;
  margin-right: 22px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.form-create-group-header {
  display: flex;
  justify-content: center;
}

.form-create-group-header h3 {
  background: #ffffff;
  margin: 8px;
  color: #494949;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.label-select-transfer {
  color: #494949 !important;
  text-align: center !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: flex;
  justify-content: center;
  margin: 5px;
  border-bottom: 1px solid black;
  height: 40px;
  align-items: center;
  text-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.form-group-create {
  display: flex;
  width: auto;
  margin-bottom: 80px;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  margin-left: 12px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
}
.form-group-edit-imovel {
  display: flex;
  width: auto;
  margin-bottom: 80px;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  overflow-x: hidden !important;
  margin-left: 12px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
}

.p-card-body {
  padding: 0px;
  padding-left: 12px;
}

.form-venda-create {
  display: flex;
  width: -webkit-fill-available;
  margin-bottom: 80px;
  height: 100%;
  border-radius: 4px;
  background: #fff;

  overflow-x: hidden !important;
  overflow-y: scroll !important;
  margin-left: 12px;
  margin-right: 22px;
}

.button-selection {
  background-color: #cbcbcb !important;
}

.div-calendar {
  display: flex;
}

@keyframes fadeOutToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-50px);
  }
}

.sidebar-visible {
  opacity: 0;
  margin-bottom: 12px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  padding: 8px;
  margin-top: 10px;
  animation: fadeOutToLeft 0.3s ease-in-out;
}

.body-content {
  margin-top: 10px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    /* Opacidade inicial */
  }

  to {
    opacity: 1;
    /* Opacidade final (totalmente visível) */
  }
}

.formulario-empreendimento {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-top: 36px;
  padding-left: 36px;
  padding-right: 36px;
}

.container-chart {
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 12px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 22px;
}

.container-dash-list-left {
  margin-top: 8px;
  display: flex;
  width: 40.7%;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 12px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 22px;
  z-index: -1;
  position: absolute;
  opacity: 0;
  /* Inicia com opacidade 0 para estar invisível */
  animation: fadeIn 0.5s ease-in-out forwards;
}

.container-dash-list-right {
  margin-top: 8px;
  display: flex;
  width: 41%;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 12px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 22px;
  z-index: -1;
  position: absolute;
  opacity: 0;
  /* Inicia com opacidade 0 para estar invisível */
  animation: fadeIn 0.5s ease-in-out forwards;
}

.container-chart-none {
  margin-top: 8px;
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 22px;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 22px;
}

.table-dash {
  min-height: 250px !important;
}

.dropdown-menu[data-bs-popper] {
  left: auto !important;
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
  display: flex;
  justify-content: center;
}
.remove-button {
  margin-left: 10px;
  cursor: pointer;
}
.p-autocomplete .p-inputtext {
  width: 100% !important;
  height: 32px !important;
}

.modal-filtro-avancado .modal-dialog .modal-content {
  width: 100% !important;
  right: auto !important;
}
.p-autocomplete-panel {
  z-index: 1060 !important;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 260% !important; */
  /* right: 390px; */
  /* padding: 20px; */
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: 2px dashed #cccccc;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.custom-table-container {
  margin-top: 12px;
  padding: 1rem;
  background-color: #fff; /* Fundo claro para melhor contraste */
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.dataTable {
  width: 100%;
  min-width: 50rem;
}

.dataTable .p-paginator {
  background-color: transparent; /* Remove o fundo do paginador */
}

/* css dos graaficos */

.content-area {
  max-width: 1280px;
  margin: 0 auto;
}

.iconLock {
  width: 5%;
}

.box {
  background-color: #fff;
  padding: 25px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.shadow {
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
}

.sparkboxes .box {
  padding-top: 10px;
  padding-bottom: 10px;
  text-shadow: 0 1px 1px 1px #666;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  position: relative;
  border-radius: 5px;
}

.progressBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sparkboxes .box .details {
  position: absolute;
  color: #fff;
  transform: scale(0.7) translate(-22px, 20px);
}

.sparkboxes strong {
  position: relative;
  z-index: 3;
  top: -8px;
  color: #fff;
}

.sparkboxes .box1 {
  background-image: linear-gradient(135deg, #abdcff 10%, #0396ff 100%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.sparkboxes .box2 {
  background-image: linear-gradient(135deg, #2afadf 10%, #4c83ff 100%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.sparkboxes .box3 {
  background-image: linear-gradient(135deg, #ffd3a5 10%, #fd6585 100%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

.sparkboxes .box4 {
  background-image: linear-gradient(135deg, #ee9ae5 10%, #5961f9 100%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}

*::-webkit-scrollbar {
  width: 8px;
}

.pagination-footer {
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;
  flex: 1;
}

.pagination-footer .page-link {
  cursor: pointer;
  color: #007bff;
}

.pagination-footer .page-link:hover {
  text-decoration: none;
}

.pagination-footer .page-item.disabled .page-link {
  pointer-events: none;
  color: #6c757d;
}

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: -webkit-fill-available;
}

.righted-header .p-column-header-content {
  display: flex;
  justify-content: flex-end;
}

.righted-content {
  display: flex;
  justify-content: flex-end;
}
.leftted-header .p-column-header-content {
  display: flex;
  justify-content: flex-start;
}

.leftted-content {
  display: flex;
  justify-content: flex-start;
}
.audit-show {
  display: flex;
  width: auto;
  flex: 1 1;
  margin-bottom: 80px;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 12px;
  padding-left: 22px;
  margin-right: 22px;
  padding-right: 22px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.button-speed-dial {
  width: 22px;
  height: 22px;
  background-color: #00000000;
  border-color: #00000000;
  border-radius: 30px;
  flex-direction: row !important;
}

.button-speed-dial .pi {
  color: #000; /* Escolha a cor que preferir */
}
.p-component {
  font-size: 10px !important;
}
p {
  font-size: 10px !important;
  align-items: center;
  display: flex;
}
a {
  align-items: center;
  display: flex;
  font-size: 10px !important;
}

label {
  align-items: center;
  display: flex;
  font-size: 10px !important;
}
.form-select {
  font-size: 10px !important;
}
.form-control {
  font-size: 10px !important;
}
.css-1jqq78o-placeholder {
  font-size: 10px !important;
}
.p-sidebar-header {
  background-color: #282c34;
}
.p-sidebar-content {
  background-color: #585c65;
  padding: 0px !important;
}
.header-sidebar-sub {
  color: #fff !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.icon-expand-submenu {
  width: 17.69px;
  height: 20px;
  position: relative;
  float: right;
}
.button-submenu {
  display: flex;
  align-items: center !important;
  padding: 20px !important;
}
.button-submenu:hover {
  background-color: #282c34;
}
.col-reajuste {
  display: flex;
  justify-content: space-between;
  margin: 14px;
  width: 100%;
}
.card-list-reajuste {
  display: flex !important;
  justify-content: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding: 5px;
  width: 75px;
  border-radius: 4px;
  background: #c0c1c2;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  flex-direction: column;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.table-memoria-calc {
  height: 100%;
  max-height: 297px;
}
.table-memoria-calc .p-datatable-wrapper {
  height: 100%;
  max-height: 297px;
}
.body-content-reajuste {
  display: flex;
  height: -webkit-fill-available;
  padding-bottom: 73px;
}
.body-content-taxa-manutencao {
  display: flex;
  padding-bottom: 73px;
}
.content-reajuste {
  padding: 0px;
  margin-top: 10px;
  width: -webkit-fill-available;
  max-width: 35%;
}
.content-reajuste-memoria {
  padding: 0px;
  margin-top: 10px;
  width: -webkit-fill-available;
  max-width: 65%;
}
@media (max-width: 1399px) {
  .body-content-reajuste {
    display: flex;
    height: -webkit-fill-available;
    flex-direction: column;
    padding-bottom: 73px;
  }
  .content-reajuste {
    padding: 0px;
    margin-top: 10px;
    width: -webkit-fill-available;
    max-width: 100%;
  }
  .content-reajuste-memoria {
    padding: 0px;
    margin-top: 10px;
    width: -webkit-fill-available;
    max-width: 100%;
  }
  .container-section-reajuste {
    display: flex;
    width: auto;
    margin-bottom: 4px;
    flex-direction: column;
    height: 99%;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
    margin-right: 22px;
    padding-right: 22px;
    padding-left: 8px;
  }
}
.div-buttons-reajuste {
  display: flex;
  justify-content: end;
  flex: 1;
  align-items: end;
}
.div-buttons-taxa {
  display: flex;
  justify-content: end;
  flex: 1;
}
.menu-context-grid-contas {
  position: absolute;
  display: flex;
  padding-left: 53px;
  width: 13%;
  border-top: none;
  z-index: 0;
}
.menu-context-grid-provedor {
  position: absolute;
  display: flex;
  padding-left: 53px;
  width: 13%;
  border-top: none;
  z-index: 0;
}
.menu-context-grid-inumado {
  position: absolute;
  padding-left: 53px;
  width: 13%;
  border-top: none;
  z-index: 0;
}
.menu-context-grid-inumado-gaveta {
  padding-left: 53px;
  width: 13%;
  border-top: none;
  z-index: 0;
}

.menu-context-grid-contrato {
  position: absolute;
  display: flex;
  padding-left: 53px;
  width: 13%;
  border-top: none;
  z-index: 0;
}

.button-menu-speedial > button {
  width: 25px;
  height: 25px;
  border-radius: 32px;
  margin-top: 1px;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0px !important;
}
.hover-effect {
  transition: fill 0.3s ease;
}

.hover-effect:hover {
  fill: rgb(61, 191, 235);
}
.custom-tree-select .p-treeselect-panel {
  width: 100% !important; /* Faz o dropdown ocupar a largura do TreeSelect */
}
.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  z-index: 1070 !important;
}

.custom-tree-select .p-treeselect-label {
  white-space: nowrap; /* Evita que os labels fiquem empilhados */
}
.p-treeselect-panel .p-treenode {
  display: block !important; /* Assegura que cada opção ocupa uma linha */
  flex-direction: column !important; /* Se flex estiver aplicado */
  grid-template-columns: none !important; /* Se grid estiver aplicado */
}
/* Aplica display block a cada nó da árvore no dropdown */
.p-treeselect-panel .p-treenode-content {
  display: block !important;
  width: 100%;
}
/* Estilo geral do painel do calendário */
.custom-calendar-panel .p-datepicker {
  width: 287px !important;
  height: auto !important;
}

/* Ajusta o tamanho das células (datas) no calendário */
.custom-calendar-panel .p-datepicker-calendar td {
  width: 2.5rem !important;
  height: 2.5rem !important;
  text-align: center;
  overflow: hidden;
  margin: 0px !important;
  padding: 0px !important;
}

/* Ajusta o tamanho do texto no calendário */
.custom-calendar-panel .p-datepicker-calendar {
  font-size: 0.85rem !important;
}

/* Ajusta o cabeçalho do calendário (mês e botões de navegação) */
.custom-calendar-panel .p-datepicker-header {
  font-size: 0.85rem !important;
  padding: 0.5rem !important; /* Ajuste para melhor espaçamento */
}

.custom-calendar-panel .p-datepicker-buttonbar {
  font-size: 0.85rem !important;
  padding: 0.5rem !important;
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white; /* Para garantir que o cabeçalho não sobreponha conteúdo */
  z-index: 1;
}

.sticky-header-provedor {
  position: sticky;
  top: 0;
  background-color: white; /* Para garantir que o cabeçalho não sobreponha conteúdo */
  z-index: 1;
  width: 3%;
}

.sticky-header-dashboard {
  position: sticky;
  top: 0;
  background-color: white; /* Para garantir que o cabeçalho não sobreponha conteúdo */
}

.p-breadcrumb ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-block-end: 0px !important;
  padding-inline-start: 0px !important;
}
.p-breadcrumb {
  display: flex;
  background: none !important;
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
  color: #ffffff;
}
.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
  color: #ffffff;
}
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
  color: #ffffff;
}
.p-dropdown-panel {
  z-index: 2000 !important;
  font-size: 12px !important;
}

.dropdown-custom .p-dropdown-label {
  font-size: 12px !important;
}

.form-group-create .p-dropdown-label {
  font-size: 9px !important;
}

.float-label {
  position: relative;
  display: flex;
  flex-direction: column;
}

.float-label label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background: white;
  padding: 0 4px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  color: #222222;
}

.float-label label.active {
  top: -15px;
  font-size: 12px;
  color: #222222;
}

.float-label select:focus + label,
.float-label select:not([value=""]) + label {
  top: -15px;
  font-size: 12px;
  color: #222222;
}

div .p-carousel-container {
  overflow-y: hidden;
  overflow-x: hidden;
}
.menu-container {
  align-items: center;
  position: relative;
}

.menu-navbar {
  display: flex;
  overflow: hidden;
  transition: transform 0.5s ease-in-out; /* Transição suave */
}

.carousel-controls button {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.carousel-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
