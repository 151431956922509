@tailwind components;
@tailwind utilities;
.p-accordion-header-link {
    text-decoration: none !important;
}
.p-accordion-toggle-icon > path {
    fill: black;
}
.rounded-modal .modal-content {
        border-radius: 15px;
    }
    .shadow-sm {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    .calendar-icon {
        width: 20px;
        cursor: pointer;
    }
    .progress-bar-custom {
        height: 25px; /* Aumenta a altura do ProgressBar */
        border-radius: 10px; /* Bordas arredondadas */
    }
    .badge-custom {
        font-size: 0.9rem;
        padding: 0.5em 0.75em;
    }
    .badge-group {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    }
    .small-font {
        font-size: 12px;
    }
    .legend {
        margin-top: 10px;
    }
    .legend-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 15px;
    }
    .legend-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
    }
    .legend-item strong {
        font-weight: bold;
        margin-top: 5px;
    }
    .value {
        font-weight: bold;
        margin-top: 2px;
    }
    /* Responsividade para legendas */
    @media (max-width: 576px) {
        .legend-item {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    /* Estilos para Detalhes Financeiros */
    .finance-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .finance-col {
        flex: 0 0 19%; /* Aproximadamente 20% menos margens */
        max-width: 19%;
        padding: 5px;
    }
    @media (max-width: 1200px) {
        .finance-col {
            flex: 0 0 24%;
            max-width: 24%;
        }
    }
    @media (max-width: 992px) {
        .finance-col {
            flex: 0 0 32%;
            max-width: 32%;
        }
    }
    @media (max-width: 768px) {
        .finance-col {
            flex: 0 0 48%;
            max-width: 48%;
        }
    }
    @media (max-width: 576px) {
        .finance-col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .finance-card {
        border: none;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        transition: transform 0.2s;
        height: 100px; 
    }
    .finance-card:hover {
        transform: translateY(-5px);
    }
    .finance-card .card-title {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .finance-card .card-text {
        font-size: 16px;
        font-weight: bold;
        align-items: center;
        text-align: center;
    }