@tailwind components;
@tailwind utilities;

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  object-fit: cover;
  height: auto;
  display: inline-block;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Overlay com opacidade e posicionamento dos botões */
.overlay {
  position: absolute;
  width: 100%;
  height: 350px;
  min-height: 350px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Tela preta com opacidade */
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.image-container:hover .overlay {
  opacity: 1; /* Mostra a sobreposição ao passar o mouse */
}

/* Estilo para os botões */
.overlay-button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overlay-button:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Muda a cor do botão ao passar o mouse */
}

.overlay-button.add {
  background-color: green;
}

.overlay-button.delete {
  background-color: red;
}

.overlay-button.expand {
  background-color: blue;
}

/* GalleriaAdvancedDemo.css */

.galleria-demo {
  .custom-indicator-galleria {
    .indicator-text {
      color: #e9ecef;
      cursor: pointer;
    }

    .p-highlight {
      .indicator-text {
        color: var(--primary-color);
      }
    }
  }

  .custom-galleria {
    &.fullscreen {
      display: flex;
      flex-direction: column;

      .p-galleria-content {
        flex-grow: 1;
        justify-content: center;
      }
    }

    .p-galleria-content {
      position: relative;
    }

    .p-galleria-thumbnail-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    .p-galleria-thumbnail-items-container {
      width: 100%;
    }

    .custom-galleria-footer {
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.9);
      color: #ffffff;

      > button {
        background-color: transparent;
        color: #ffffff;
        border: 0 none;
        border-radius: 0;
        margin: 0.2rem 0;

        &.fullscreen-button {
          margin-left: auto;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }

    .title-container {
      > span {
        font-size: 0.9rem;
        padding-left: 0.829rem;

        &.title {
          font-weight: bold;
        }
      }
    }
  }
}
.p-chip-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
