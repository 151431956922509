@tailwind components;
@tailwind utilities;

.filtro-form {
  margin-bottom: 25px;
  padding: 5px;
  padding-right: 25px;
  margin-left: 11px;
}
.table-card {
  width: auto;
  height: 448px;
  margin-bottom: 65px;
  overflow-x: hidden !important;
}

.table-card-jazigo {
  margin-bottom: 65px;
}

.card-demonstrativo-adicionar {
  display: flex;
  width: 25%;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #189428;
  margin-right: 8px;
  padding-right: 0px;
  cursor: pointer;
}
.card-demonstrativo-adicionar:hover {
  background: #0f6c1d;
}

.card-demonstrativo-editar {
  display: flex;
  width: 25%;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #e8550f;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 8px;
  padding-right: 0px;
  cursor: pointer;
}
.card-demonstrativo-editar:hover {
  background: #c8460f;
}

.card-demonstrativo-jazigo {
  display: flex;
  width: 25%;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #0d70c2;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 8px;
  padding-right: 0px;
  cursor: pointer;
}
.card-demonstrativo-jazigo:hover {
  background: #095ba0;
}

.card-demonstrativo-indices {
  display: flex;
  width: 25%;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #e9830e;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 8px;
  padding-right: 0px;
  cursor: pointer;
}
.card-demonstrativo-indices:hover {
  background: #d1720d;
}

.card-demonstrativo-venda-avulsa {
  display: flex;
  width: 25%;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #1c914d;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 8px;
  padding-right: 0px;
  cursor: pointer;
}
.card-demonstrativo-venda-avulsa:hover {
  background: #178440;
}

.card-demonstrativo-none {
  display: flex;
  width: auto;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 8px;
  padding-right: 8px;
  padding-left: 8px;
}

.content-cards {
  display: flex;
  width: auto;
  border-radius: 4px;
  margin-right: 14px;
  margin-top: 4px;
  background: none;
  box-shadow: none;
}
.content-cards-none {
  display: flex;
  width: auto;
  border-radius: 4px;
  margin-right: 14px;
  margin-left: 22px;
  margin-top: 4px;
  background: none;
  box-shadow: none;
}
.traco-card {
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 4px;
}

.label-card {
  display: flex;
  align-items: end;
  font-size: 16px;
  color: #ffff;
}

.label-button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #ffff;
  margin-bottom: 4px;
  height: 20px;
  background-color: #0d71c200;
  border: none;
}

.disabled-indice {
  background: #d1720d;
  cursor: not-allowed;
}
.disabled-jazigo {
  background: #095ba0;
  cursor: not-allowed;
}
.disabled-text {
  opacity: 0.4;
}

.img-card {
  width: 65px;
  opacity: 30%;
}

.modal-input-inumado {
  background-color: rgba(0, 0, 0, 0.623);
}

.fade.modal-input-inumado.modal.show {
  padding-block-start: 5%;
}

.modal-input-venda {
  background-color: rgba(0, 0, 0, 0.623);
}
.modal-input-impressao {
  background-color: rgba(0, 0, 0, 0.623);
  display: inline-block;
}

.fade.modal-input-venda.modal.show {
  padding-block-start: 0%;
}
.p-venda{
  margin-top: 11px;
}
.container-section-table {
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-right: 22px;
}

.container-section-filtro-none {
  display: flex;
  width: auto;
  margin-bottom: 4px;
  flex-direction: column;
  height: 99%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
  margin-left: 22px;
  margin-right: 10px;
  padding-right: 8px;
  padding-left: 8px;
}

.col-reajuste-data {
  display: flex;
  justify-content: center;
}

.p-menuitem-link {
  text-decoration: none;
}
.p-autocomplete-dropdown {
  background-color: #494949 !important;
  border: none;
  height: 32px;
  width: 30px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.32);
}
.modal-footer {
  align-items: unset !important;
}
.p-steps-number {
  background-color: #e8550f !important;
  color: #fff !important;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background-color: #494949 !important;
  color: #fff !important;
}
.button-form {
  margin-right: 5px;
  border: none !important;
  background-color: #494949 !important;
  height: 30px;
  background: #494949;
  border-radius: 42px;
  position: relative;
  float: right;
  border: 1px solid #494949;
}
.p-inputnumber-button {
  border: none !important;
  background-color: #494949 !important;
  width: 15px !important;
  background: #494949;
}
.p-inputnumber-button-group {
  height: 32px;
}
.linha-vertical {
  display: flex;
  align-self: center;
  height: 20px; /*Altura da linha*/
  border-left: 1px solid; /* Adiciona borda esquerda na div como ser fosse uma linha.*/
}

.date-validation {
  border-color: red;
}

.venda-jazigo {
  margin-right: 22px;
  margin-left: 22px;
  display: flex;
}
.p-inputtext {
    width: 100% !important;
    height: fit-content;
}
 @media (max-width: 675px) {
  .form-user-create {
    margin-left:22px;
  }
 }